<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.stop.alertSubscription.title')"
			:modelName="modelName"
			:dialogName="alertSubscription"
			:onOk="onOkAlertSubscription"
			:onShow="onShowAlertSubscription"
			:showDialogProp="showDialogAlertSubscription"
		>
			<template slot="message">
				<v-container>
					<v-layout wrap align-center>
						<v-flex xs10>
							<span class="notif__title">{{ $t('operationsvisitdetailnotifications.notifications') }}</span>
						</v-flex>
						<v-flex xs2>
							<v-switch v-model="notifications"></v-switch>
						</v-flex>
					</v-layout>

					<v-layout v-if="model" wrap class="pt-3">
						<v-flex xs12>
							<span class="notif__subtitle">{{ $t('operationsvisitdetailnotifications.events') }}</span>
						</v-flex>
						<v-flex xs6 v-for="(event, index) in events" :key="index">
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-checkbox
										v-model="model[event.property]"
										:readonly="!notifications"
										color="primary"
										:ripple="false"
										hide-details
									>
										<span slot="label" v-on="on">{{ event.name }}</span>
									</v-checkbox>
								</template>
								<span>{{ event.description }}</span>
							</v-tooltip>
						</v-flex>
					</v-layout>

					<v-layout v-if="model" wrap class="pt-3">
						<v-flex xs12>
							<span class="notif__subtitle">{{ $t('operationsvisitdetailnotifications.status.title') }}</span>
						</v-flex>
						<v-flex xs12>
							<v-checkbox
								:label="$t('operationsvisitdetailnotifications.status.cancelled')"
								:readonly="!notifications"
								v-model="model.cancelled"
								color="primary"
								:ripple="false"
								hide-details
							></v-checkbox>
						</v-flex>
						<v-flex xs12>
							<v-checkbox
								:label="$t('operationsdatatable.status.waiting')"
								:readonly="!notifications"
								v-model="model.waiting"
								color="primary"
								:ripple="false"
								hide-details
							></v-checkbox>
						</v-flex>
					</v-layout>
				</v-container>
			</template>
		</pui-modal-dialog-form>

		<!--<pui-modal-dialog-form
			:titleText="$t('modal.stop.validateStopRules.title')"
			:modelName="modelName"
			:dialogName="validateStopRules"
			:onShow="onShowValidateStopRules"
			disableCancel
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<p v-if="!validationDone">{{ $t('modal.stop.validateStopRules.processingValidation') }}</p>
						<p v-else>{{ validationResult }}</p>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>-->

		<pui-modal-dialog-form
			:titleText="$t('modal.stop.denyStops.title')"
			:modelName="modelName"
			:dialogName="denyStops"
			:onShow="onShowDenyStops"
			:onOk="onOkDenyStops"
		>
			<template slot="message">
				<v-row>
					<v-col cols="12">
						<pui-text-area
							:id="`commentsstatus-stop`"
							v-model="stopcommentstatus"
							maxlength="350"
							toplabel
							:label="$t('stop.commentsstatus')"
						></pui-text-area>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.stop.initiateStop.title')"
			:modelName="modelName"
			dialogName="initiateStop"
			:onOk="onOkInitiateStop"
			:showDialogProp="showDialogInitiateStop"
			:onShow="onShowInitiateStop"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense class="mb-2 customHeader">
					<v-col cols="3">
						<pui-field :label="$t('header.stop.ship')" :value="this.model.vesselname"></pui-field>
					</v-col>
					<v-col cols="1">
						<pui-field :label="$t('header.stop.vesseldraught')" :value="this.model.vesseldraught"></pui-field>
					</v-col>
					<v-col cols="4">
						<pui-field :label="$t('header.stop.vesseltype')" :value="this.model.vesseltypedesc"></pui-field>
					</v-col>
					<v-col cols="2">
						<pui-field :label="$t('header.stop.portcallnumber')" :value="this.model.portcallnumber"></pui-field>
					</v-col>
					<v-col cols="2">
						<pui-field :label="$t('header.stop.num')" :value="this.model.num"></pui-field>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('stop.actualdata')">
							<v-row dense>
								<v-col cols="6">
									<pui-date-field
										:id="`ata-${modelName}`"
										:label="$t('stop.ata')"
										v-model="model.startdate"
										toplabel
										time
										required
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`berthingtypeactual-${modelName}`"
										:attach="`berthingtypeactual-${modelName}`"
										:label="$t('stop.berthingtype')"
										toplabel
										clearable
										v-model="model"
										modelName="berthingtype"
										:itemsToSelect="[{ berthingtypecode: model.berthingtypereal }]"
										:modelFormMapping="{ berthingtypecode: 'berthingtypereal' }"
										itemValue="berthingtypecode"
										itemText="berthingtypename"
										:order="{ berthingtypename: 'asc' }"
										reactive
										:fixedFilter="filterByReal"
									></pui-select>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`activitytypereal-${modelName}`"
										:attach="`activitytypereal-${modelName}`"
										:label="$t('stop.activitytype')"
										toplabel
										clearable
										v-model="model"
										modelName="activitytype"
										:itemsToSelect="[{ code: model.activitytypereal }]"
										:modelFormMapping="{ code: 'activitytypereal' }"
										itemValue="code"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-select
										:id="`berthactual-${modelName}`"
										:attach="`berthactual-${modelName}`"
										:label="$t('stop.berthactual')"
										toplabel
										clearable
										v-model="model"
										modelName="berth"
										:itemsToSelect="[{ id: model.berthactual }]"
										:modelFormMapping="{ id: 'berthactual' }"
										itemValue="id"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
										:fixedFilter="portIdFilter"
										@input="bollardActualFixedFilterMethod()"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardini-${modelName}`"
										:attach="`bollardini-${modelName}`"
										:label="$t('stop.bollardini')"
										toplabel
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: model.bollardinirealid }]"
										:modelFormMapping="{ id: 'bollardinirealid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardIniActualFixedFilter"
										:rules="bollardRules"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardend-${modelName}`"
										:attach="`bollardend-${modelName}`"
										:label="$t('stop.bollardend')"
										toplabel
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: model.bollardendrealid }]"
										:modelFormMapping="{ id: 'bollardendrealid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardEndActualFixedFilter"
										:rules="bollardRules"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="6">
									<pui-date-field
										v-show="model.atavisit"
										:id="`atavisit-${modelName}`"
										:label="$t('stop.atavisit')"
										v-model="model.atavisit"
										toplabel
										time
										reactive
									></pui-date-field>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.stop.updateActualStop.title')"
			:modelName="modelName"
			dialogName="updateActualStop"
			:onOk="onOkUpdateActualStop"
			:showDialogProp="showDialogUpdateActualStop"
			:onShow="onShowUpdateActualStop"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense class="mb-2 customHeader">
					<v-col cols="2">
						<pui-field :label="$t('header.stop.ship')" :value="this.model.vesselname"></pui-field>
					</v-col>
					<v-col cols="1">
						<pui-field :label="$t('header.stop.vesseldraught')" :value="this.model.vesseldraught"></pui-field>
					</v-col>
					<v-col cols="5">
						<pui-field :label="$t('header.stop.vesseltype')" :value="this.model.vesseltypedesc"></pui-field>
					</v-col>
					<v-col cols="2">
						<pui-field :label="$t('header.stop.portcallnumber')" :value="this.model.portcallnumber"></pui-field>
					</v-col>
					<v-col cols="2">
						<pui-field :label="$t('header.stop.num')" :value="this.model.num"></pui-field>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('stop.actualdata')">
							<v-row dense>
								<v-col cols="3">
									<pui-date-field
										:id="`ata-${modelName}`"
										:label="$t('stop.ata')"
										v-model="model.startdate"
										toplabel
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`atd-${modelName}`"
										:label="$t('stop.atd')"
										v-model="model.enddate"
										toplabel
										:disabled="model.statusid === 'IN'"
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`berthingtypereal-${modelName}`"
										:attach="`berthingtypereal-${modelName}`"
										:label="$t('stop.berthingtype')"
										toplabel
										clearable
										v-model="model"
										modelName="berthingtype"
										:itemsToSelect="[{ berthingtypecode: model.berthingtypereal }]"
										:modelFormMapping="{ berthingtypecode: 'berthingtypereal' }"
										itemValue="berthingtypecode"
										itemText="berthingtypename"
										:order="{ berthingtypename: 'asc' }"
										reactive
										:fixedFilter="filterByReal"
									></pui-select>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`activitytypereal-${modelName}`"
										:attach="`activitytypereal-${modelName}`"
										:label="$t('stop.activitytype')"
										toplabel
										clearable
										v-model="model"
										modelName="activitytype"
										:itemsToSelect="[{ code: model.activitytypereal }]"
										:modelFormMapping="{ code: 'activitytypereal' }"
										itemValue="code"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-select
										:id="`berthrealid-${modelName}`"
										:attach="`berthrealid-${modelName}`"
										:label="$t('stop.berthactual')"
										toplabel
										clearable
										v-model="model"
										modelName="berth"
										:itemsToSelect="[{ id: model.berthrealid }]"
										:modelFormMapping="{ id: 'berthrealid' }"
										itemValue="id"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
										:fixedFilter="portIdFilter"
										@input="bollardFixedFilter()"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardini-${modelName}`"
										:attach="`bollardini-${modelName}`"
										:label="$t('stop.bollardini')"
										toplabel
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: model.bollardiniid }]"
										:modelFormMapping="{ id: 'bollardiniid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardIniFixedFilter"
										:rules="bollardRules"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardend-${modelName}`"
										:attach="`bollardend-${modelName}`"
										:label="$t('stop.bollardend')"
										toplabel
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: model.bollardendid }]"
										:modelFormMapping="{ id: 'bollardendid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardEndFixedFilter"
										:rules="bollardRules"
									></pui-select>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.stop.finishStop.title')"
			:modelName="modelName"
			dialogName="finishStop"
			:onOk="onOkFinishStop"
			:showDialogProp="showDialogFinishStop"
			:onShow="onShowFinishStop"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense class="mb-2 customHeader">
					<v-col cols="2">
						<pui-field :label="$t('header.stop.ship')" :value="this.model.vesselname"></pui-field>
					</v-col>
					<v-col cols="1">
						<pui-field :label="$t('header.stop.vesseldraught')" :value="this.model.vesseldraught"></pui-field>
					</v-col>
					<v-col cols="5">
						<pui-field :label="$t('header.stop.vesseltype')" :value="this.model.vesseltypedesc"></pui-field>
					</v-col>
					<v-col cols="2">
						<pui-field :label="$t('header.stop.portcallnumber')" :value="this.model.portcallnumber"></pui-field>
					</v-col>
					<v-col cols="2">
						<pui-field :label="$t('header.stop.num')" :value="this.model.num"></pui-field>
					</v-col>
				</v-row>
				<v-row dense class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('stop.actualdata')">
							<v-row dense>
								<v-col cols="3">
									<pui-date-field
										:id="`ata-${modelName}`"
										:label="$t('stop.ata')"
										v-model="model.ata"
										toplabel
										disabled
										time
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-date-field
										:id="`atd-${modelName}`"
										:label="$t('stop.atd')"
										v-model="model.atd"
										toplabel
										time
										:min="model.ata"
										required
									></pui-date-field>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`berthingtypeactual-${modelName}`"
										:attach="`berthingtypeactual-${modelName}`"
										:label="$t('stop.berthingtype')"
										toplabel
										clearable
										v-model="model"
										modelName="berthingtype"
										:itemsToSelect="[{ berthingtypecode: model.berthingtypeactual }]"
										:modelFormMapping="{ berthingtypecode: 'berthingtypeactual' }"
										itemValue="berthingtypecode"
										itemText="berthingtypename"
										:order="{ berthingtypename: 'asc' }"
										reactive
										disabled
									></pui-select>
								</v-col>
								<v-col cols="3">
									<pui-select
										:id="`activitytypeactual-${modelName}`"
										:attach="`activitytypeactual-${modelName}`"
										:label="$t('stop.activitytype')"
										toplabel
										clearable
										v-model="model"
										modelName="activitytype"
										:itemsToSelect="[{ code: model.activitytypeactual }]"
										:modelFormMapping="{ code: 'activitytypeactual' }"
										itemValue="code"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
										disabled
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-select
										:id="`berthactual-${modelName}`"
										:attach="`berthactual-${modelName}`"
										:label="$t('stop.berthactual')"
										toplabel
										clearable
										v-model="model"
										modelName="berth"
										:itemsToSelect="[{ id: model.berthactual }]"
										:modelFormMapping="{ id: 'berthactual' }"
										itemValue="id"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
										disabled
										:fixedFilter="portIdFilter"
										@input="bollardActualFixedFilterMethod()"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardini-${modelName}`"
										:attach="`bollardini-${modelName}`"
										:label="$t('stop.bollardini')"
										toplabel
										v-model="model"
										modelName="bollard"
										:itemsToSelect="[{ id: model.bollardiniactual }]"
										:modelFormMapping="{ id: 'bollardiniactual' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										disabled
										:fixedFilter="bollardIniActualFixedFilter"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardend-${modelName}`"
										:attach="`bollardend-${modelName}`"
										:label="$t('stop.bollardend')"
										toplabel
										v-model="model"
										disabled
										modelName="bollard"
										:itemsToSelect="[{ id: model.bollardendactual }]"
										:modelFormMapping="{ id: 'bollardendactual' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:fixedFilter="bollardEndActualFixedFilter"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="6">
									<pui-date-field
										v-show="model.atdvisit"
										:id="`atdvisit-${modelName}`"
										:label="$t('stop.atdvisit')"
										v-model="model.atdvisit"
										toplabel
										time
									></pui-date-field>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.stop.planStop')"
			:modelName="modelName"
			:dialogName="planStop"
			:onOk="onOkPlanStops"
			:onShow="onShowPlanStops"
			:showDialogProp="showDialogPlanStop"
		>
			<template slot="message">
				<v-row class="mt-1">
					<v-col cols="12">
						<div v-for="(stop, index) in stopsToPlan" :key="index">
							<v-row dense class="mb-2 customHeader">
								<v-col cols="2">
									<pui-field :label="$t('header.stop.ship')" :value="stop.vesselname"></pui-field>
								</v-col>
								<v-col cols="1">
									<pui-field :label="$t('header.stop.vesseldraught')" :value="stop.vesseldraught"></pui-field>
								</v-col>
								<v-col cols="5">
									<pui-field :label="$t('header.stop.vesseltype')" :value="stop.vesseltypedesc"></pui-field>
								</v-col>
								<v-col cols="2">
									<pui-field :label="$t('header.stop.portcallnumber')" :value="stop.portcallnumber"></pui-field>
								</v-col>
								<v-col cols="2">
									<pui-field :label="$t('header.stop.num')" :value="stop.num"></pui-field>
								</v-col>
							</v-row>
							<pui-field-set class="mt-4">
								<v-row dense>
									<v-col cols="5" class="mr-auto">
										<label class="v-label--required pa-2">{{ $t('modal.stop.locationid') }}</label>
										<v-autocomplete
											class="pa-2 pt-0"
											outlined
											dense
											v-model="stop.berthplan"
											:items="$store.getters.getRawPortBerths.filter((berth) => berth.portid === stop.portid)"
											item-value="berthcode"
											clearable
											:item-text="(item) => ` ${item.name}`"
											@change="getBollards(pagination)"
											:key="berthsKey"
										>
										</v-autocomplete>
									</v-col>
									<v-col cols="2">
										<label class="pa-2">{{ $t('modal.stop.bollardini') }}</label>
										<v-autocomplete
											class="pa-2 pt-0"
											outlined
											dense
											:key="bollardsKey"
											v-model="stop.bollardiniplan"
											:items="iniBollards"
											item-value="bolcode"
											clearable
											:item-text="(item) => ` ${item.bolcode}`"
											:rules="bollardRules"
											@change="changeBollard(stop.bollardiniplan, iniBollards, 'ini')"
										>
										</v-autocomplete>
									</v-col>

									<v-col cols="2">
										<label class="pa-2">{{ $t('modal.stop.bollardend') }}</label>
										<v-autocomplete
											class="pa-2 pt-0"
											outlined
											dense
											v-model="stop.bollardendplan"
											:items="endBollards"
											item-value="bolcode"
											clearable
											:item-text="(item) => ` ${item.bolcode}`"
											:rules="bollardRules"
											@change="changeBollard(stop.bollardendplan, endBollards, 'end')"
										>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="4">
										<pui-select
											id="berthtype-types"
											:label="$t('operations.stops.berthtype')"
											toplabel
											required
											v-model="berthType"
											modelName="berthingtype"
											:modelFormMapping="{
												berthingtypecode: 'berthingtypecode',
												berthingtypename: 'berthingtypename'
											}"
											:itemsToSelect="[
												{
													berthingtypecode: berthType.berthingtypecode,
													berthingtypename: berthType.berthingtypename
												}
											]"
											:itemValue="['berthingtypecode', 'berthingtypename']"
											:itemText="(item) => `${item.berthingtypename}`"
											:order="{ berthingtypename: 'asc' }"
											reactive
											clearable
											:fixedFilter="filterByReal"
										></pui-select>
									</v-col>
									<v-col cols="4">
										<pui-date-field
											:id="`eta-${modelName}`"
											:label="$t('stop.eta')"
											v-model="stop.etaplan"
											toplabel
											required
											time
										></pui-date-field>
									</v-col>
									<v-col cols="4">
										<pui-date-field
											:id="`etd-${modelName}`"
											:label="$t('stop.etd')"
											v-model="stop.etdplan"
											toplabel
											required
											time
										></pui-date-field>
									</v-col>
								</v-row>
							</pui-field-set>
						</div>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.stop.authorizeStop')"
			:modelName="modelName"
			:dialogName="authorizeStop"
			:onOk="onOkAuthorizeStop"
			:onShow="onShowAuthorizeStop"
			:showDialogProp="showDialogAuthorizeStop"
		>
			<template slot="message">
				<v-row class="mt-1">
					<v-col cols="12">
						<div v-for="(stop, index) in stopsToAuthorize" :key="index">
							<v-row dense class="mb-2 customHeader">
								<v-col cols="2">
									<pui-field :label="$t('header.stop.ship')" :value="stop.vesselname"></pui-field>
								</v-col>
								<v-col cols="1">
									<pui-field :label="$t('header.stop.vesseldraught')" :value="stop.vesseldraught"></pui-field>
								</v-col>
								<v-col cols="5">
									<pui-field :label="$t('header.stop.vesseltype')" :value="stop.vesseltypedesc"></pui-field>
								</v-col>
								<v-col cols="2">
									<pui-field :label="$t('header.stop.portcallnumber')" :value="stop.portcallnumber"></pui-field>
								</v-col>
								<v-col cols="2">
									<pui-field :label="$t('header.stop.num')" :value="stop.num"></pui-field>
								</v-col>
							</v-row>
							<pui-field-set class="mt-4">
								<v-row dense>
									<v-col cols="5" class="mr-auto">
										<label class="v-label--required pa-2">{{ $t('modal.stop.locationid') }}</label>
										<v-autocomplete
											class="pa-2 pt-0"
											outlined
											dense
											v-model="stop.berthauth"
											:items="$store.getters.getRawPortBerths.filter((berth) => berth.portid === stop.portid)"
											item-value="berthcode"
											clearable
											:item-text="(item) => ` ${item.name}`"
											@change="getBollards(pagination)"
											:key="berthsKey"
										>
										</v-autocomplete>
									</v-col>

									<v-col cols="2">
										<label class="pa-2">{{ $t('modal.stop.bollardini') }}</label>
										<v-autocomplete
											class="pa-2 pt-0"
											outlined
											dense
											:key="bollardsKey"
											v-model="stop.bollardiniauth"
											:items="iniBollards"
											item-value="bolcode"
											clearable
											:item-text="(item) => ` ${item.bolcode}`"
											:rules="bollardRules"
											@change="changeBollard(stop.bollardiniauth, iniBollards, 'ini')"
										>
										</v-autocomplete>
									</v-col>

									<v-col cols="2">
										<label class="pa-2">{{ $t('modal.stop.bollardend') }}</label>
										<v-autocomplete
											class="pa-2 pt-0"
											outlined
											dense
											v-model="stop.bollardendauth"
											:items="endBollards"
											item-value="bolcode"
											clearable
											:item-text="(item) => ` ${item.bolcode}`"
											:rules="bollardRules"
											@change="changeBollard(stop.bollardendauth, endBollards, 'end')"
										>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="4">
										<pui-select
											id="berthtype-types"
											:label="$t('operations.stops.berthtype')"
											toplabel
											required
											v-model="berthType"
											modelName="berthingtype"
											:modelFormMapping="{
												berthingtypecode: 'berthingtypecode',
												berthingtypename: 'berthingtypename'
											}"
											:itemsToSelect="[
												{
													berthingtypecode: berthType.berthingtypecode,
													berthingtypename: berthType.berthingtypename
												}
											]"
											:itemValue="['berthingtypecode', 'berthingtypename']"
											:itemText="(item) => `${item.berthingtypename}`"
											:order="{ berthingtypename: 'asc' }"
											reactive
											clearable
											:fixedFilter="filterByReal"
										></pui-select>
									</v-col>
									<v-col cols="4" v-if="stop.pbaestimated">
										<pui-date-field
											:id="`pbaestimated-${modelName}`"
											:label="$t('stop.pbaestimated')"
											v-model="stop.pbaestimated"
											toplabel
											disabled
											time
										></pui-date-field>
									</v-col>
									<v-col cols="4"></v-col>
									<v-col cols="4">
										<pui-date-field
											:id="`eta-${modelName}`"
											:label="$t('stop.eta')"
											v-model="stop.etaauth"
											toplabel
											@input="updateEntryDate"
											required
											time
										></pui-date-field>
									</v-col>
									<v-col cols="4" v-if="stop.pbaestimated">
										<pui-date-field
											:id="`pbaauthorized-${modelName}`"
											:label="$t('stop.pbaauthorized')"
											v-model="stop.pbaauthorized"
											toplabel
											required
											:key="keyPbaAuth"
											time
										></pui-date-field>
									</v-col>
									<v-col cols="4">
										<pui-date-field
											:id="`etd-${modelName}`"
											:label="$t('stop.etd')"
											v-model="stop.etdauth"
											toplabel
											required
											time
										></pui-date-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12">
										<pui-text-area
											:id="`stop-comments`"
											v-model="stop.comments"
											:label="$t('stop.comments')"
											toplabel
											maxlength="300"
										></pui-text-area>
									</v-col>
								</v-row>
							</pui-field-set>
						</div>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.stop.authorizeStops')"
			:modelName="modelName"
			:dialogName="authorizeStops"
			:onShow="onShowAuthorizeStops"
			:onOk="onOkAuthorizeStop"
		>
			<template slot="message">
				<v-row class="mt-1">
					<v-col cols="1" class="d-flex align-center"
						><v-btn icon @click="previous()" :disabled="pagination <= 0">
							<v-icon>fas fa-chevron-left</v-icon>
						</v-btn>
					</v-col>
					<v-col cols="10">
						<div v-for="(stop, index) in stopsToAuthorize" :key="index" v-show="index == pagination">
							<v-row dense class="mb-2 customHeader">
								<v-col cols="2">
									<pui-field :label="$t('header.stop.ship')" :value="stop.vesselname"></pui-field>
								</v-col>
								<v-col cols="1">
									<pui-field :label="$t('header.stop.vesseldraught')" :value="stop.vesseldraught"></pui-field>
								</v-col>
								<v-col cols="5">
									<pui-field :label="$t('header.stop.vesseltype')" :value="stop.vesseltypedesc"></pui-field>
								</v-col>
								<v-col cols="2">
									<pui-field :label="$t('header.stop.portcallnumber')" :value="stop.portcallnumber"></pui-field>
								</v-col>
								<v-col cols="2">
									<pui-field :label="$t('header.stop.num')" :value="stop.num"></pui-field>
								</v-col>
							</v-row>
							<pui-field-set class="mt-4">
								<v-row dense>
									<v-col cols="5" class="mr-auto">
										<label class="v-label--required pa-2">{{ $t('modal.stop.locationid') }}</label>
										<v-autocomplete
											class="pa-2 pt-0"
											outlined
											dense
											v-model="stop.berthauth"
											:items="$store.getters.getRawPortBerths.filter((berth) => berth.portid === stop.portid)"
											item-value="berthcode"
											clearable
											:item-text="(item) => ` ${item.name}`"
										>
										</v-autocomplete>
									</v-col>

									<v-col cols="2">
										<label class="v-label--required pa-2">{{ $t('modal.stop.bollardini') }}</label>
										<v-autocomplete
											class="pa-2 pt-0"
											outlined
											dense
											:key="bollardsKey"
											v-model="stop.bollardiniauth"
											:items="iniBollards"
											item-value="bolcode"
											clearable
											:item-text="(item) => ` ${item.bolcode}`"
										>
										</v-autocomplete>
									</v-col>

									<v-col cols="2">
										<label class="v-label--required pa-2">{{ $t('modal.stop.bollardend') }}</label>
										<v-autocomplete
											class="pa-2 pt-0"
											outlined
											dense
											v-model="stop.bollardendauth"
											:items="endBollards"
											item-value="bolcode"
											clearable
											:item-text="(item) => ` ${item.bolcode}`"
										>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="4">
										<pui-select
											id="berthtype-types"
											:label="$t('operations.stops.berthtype')"
											toplabel
											required
											v-model="berthType"
											modelName="berthingtype"
											:modelFormMapping="{
												berthingtypecode: 'berthingtypecode',
												berthingtypename: 'berthingtypename'
											}"
											:itemsToSelect="[
												{
													berthingtypecode: berthType.berthingtypecode,
													berthingtypename: berthType.berthingtypename
												}
											]"
											:itemValue="['berthingtypecode', 'berthingtypename']"
											:itemText="(item) => `${item.berthingtypename}`"
											:order="{ berthingtypename: 'asc' }"
											reactive
											clearable
											:fixedFilter="filterByReal"
										></pui-select>
									</v-col>
									<v-col cols="4">
										<pui-date-field
											:id="`eta-${modelName}`"
											:label="$t('stop.eta')"
											v-model="stop.etaauth"
											toplabel
											required
											time
										></pui-date-field>
									</v-col>
									<v-col cols="4">
										<pui-date-field
											:id="`etd-${modelName}`"
											:label="$t('stop.etd')"
											v-model="stop.etdauth"
											toplabel
											required
											time
										></pui-date-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12">
										<pui-text-area
											:id="`stop-comments`"
											v-model="stop.comments"
											:label="$t('stop.comments')"
											toplabel
											maxlength="300"
										></pui-text-area>
									</v-col>
								</v-row>
							</pui-field-set>
						</div>
					</v-col>
					<v-col cols="1" class="d-flex align-center">
						<v-btn icon :disabled="stopsToAuthorize.length - 1 <= pagination" @click="next()">
							<v-icon>fas fa-chevron-right</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.stop.updateAuthStop')"
			:modelName="modelName"
			:dialogName="updateAuthStop"
			:onShow="onShowUpdateAuthStop"
			:onOk="onOkUpdateAuthStop"
			:showDialogProp="showDialogUpdateAuthStop"
		>
			<template slot="message" slot-scope="data">
				<v-row class="mt-1">
					<v-col cols="12">
						<pui-field-set class="mt-4">
							<v-row dense>
								<v-col cols="5" class="mr-auto">
									<label class="v-label--required pa-2">{{ $t('modal.stop.locationid') }}</label>
									<v-autocomplete
										class="pa-2 pt-0"
										outlined
										dense
										v-model="data.modalData.locationid"
										:items="$store.getters.getRawPortBerths.filter((berth) => berth.portid === stop.portid)"
										item-value="id"
										clearable
										:item-text="(item) => `${item.name}`"
										@change="getBollards(pagination)"
										:key="berthsKey"
									>
									</v-autocomplete>
								</v-col>

								<v-col cols="2">
									<pui-select
										:id="`bollardini-${modelName}`"
										:attach="`bollardini-${modelName}`"
										:label="$t('stop.bollardini')"
										toplabel
										required
										v-model="data.modalData"
										modelName="bollard"
										:itemsToSelect="[{ id: data.modalData.bollardiniid }]"
										:modelFormMapping="{ id: 'bollardiniid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:rules="bollardRules"
										:fixedFilter="bollardIniAuthFixedFilter(data.modalData)"
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`bollardend-${modelName}`"
										:attach="`bollardend-${modelName}`"
										:label="$t('stop.bollardend')"
										toplabel
										required
										v-model="data.modalData"
										modelName="bollard"
										:itemsToSelect="[{ id: data.modalData.bollardendid }]"
										:modelFormMapping="{ id: 'bollardendid' }"
										itemValue="id"
										itemText="bolcode"
										:order="{ orderby: 'asc' }"
										reactive
										:rules="bollardRules"
										:fixedFilter="bollardEndAuthFixedFilter(data.modalData)"
									></pui-select>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="4">
									<pui-select
										:id="`berthingtypeactual-${modelName}`"
										:attach="`berthingtypeactual-${modelName}`"
										:label="$t('stop.berthingtype')"
										toplabel
										clearable
										v-model="data.modalData"
										modelName="berthingtype"
										:itemsToSelect="[{ berthingtypecode: data.modalData.berthingtype }]"
										:modelFormMapping="{ berthingtypecode: 'berthingtype' }"
										itemValue="berthingtypecode"
										itemText="berthingtypename"
										:order="{ berthingtypename: 'asc' }"
										reactive
										:fixedFilter="filterByReal"
									></pui-select>
								</v-col>
								<v-col cols="4" v-if="data.modalData.pbaestimated">
									<pui-date-field
										:id="`pbaestimated-${modelName}`"
										:label="$t('stop.pbaestimated')"
										v-model="data.modalData.pbaestimated"
										toplabel
										disabled
										time
									></pui-date-field>
								</v-col>
								<v-col cols="4"></v-col>
								<v-col cols="4">
									<pui-date-field
										:id="`eta-${modelName}`"
										:label="$t('stop.eta')"
										v-model="data.modalData.eta"
										toplabel
										required
										time
									></pui-date-field>
								</v-col>
								<v-col cols="4" v-if="data.modalData.pbaauthorized">
									<pui-date-field
										:id="`pbaauthorized-${modelName}`"
										:label="$t('stop.pbaauthorized')"
										v-model="data.modalData.pbaauthorized"
										toplabel
										required
										time
									></pui-date-field>
								</v-col>
								<v-col cols="4">
									<pui-date-field
										:id="`etd-${modelName}`"
										:label="$t('stop.etd')"
										v-model="data.modalData.etd"
										toplabel
										required
										time
									></pui-date-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<pui-text-area
										:id="`stop-comments`"
										v-model="data.modalData.comments"
										:label="$t('stop.comments')"
										toplabel
										maxlength="300"
									></pui-text-area>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.stop.editRequestedBollards.title')"
			:modelName="modelName"
			:dialogName="editRequestedBollards"
			:onShow="onShowEditRequestedBollards"
			:onOk="onOkEditRequestedBollards"
			:showDialogProp="showDialogEditRequestedBollards"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense class="mb-2 customHeader">
					<v-col cols="3">
						<pui-field :label="$t('header.stop.portcallnumber')" :value="model.portcallnumber"></pui-field>
					</v-col>
					<v-col cols="3">
						<pui-field :label="$t('header.stop.portname')" :value="model.portname"></pui-field>
					</v-col>
					<v-col cols="3">
						<pui-field :label="$t('header.stop.vesselname')" :value="model.vesselname"></pui-field>
					</v-col>
					<v-col cols="3">
						<pui-field :label="$t('header.stop.num')" :value="model.num"></pui-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col>
						<pui-select
							:id="`locationid-${modelName}`"
							:attach="`locationid-${modelName}`"
							:label="$t('modal.stop.locationid')"
							toplabel
							clearable
							required
							v-model="model"
							modelName="berth"
							:itemsToSelect="[{ id: model.locationid || model.berthauthid || model.berthsoliid }]"
							:modelFormMapping="{ id: 'berthsoliid' }"
							itemValue="id"
							itemText="name"
							:order="{ name: 'asc' }"
							reactive
							disabled
							:fixedFilter="portIdFilter"
							@input="bollardFixedFilter()"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<pui-select
							:id="`bollardini-${modelName}`"
							:attach="`bollardini-${modelName}`"
							:label="$t('stop.bollardini')"
							toplabel
							required
							v-model="model"
							modelName="bollard"
							:itemsToSelect="[{ id: model.bollardiniid }]"
							:modelFormMapping="{ id: 'bollardiniid' }"
							itemValue="id"
							itemText="bolcode"
							:order="{ orderby: 'asc' }"
							reactive
							:fixedFilter="bollardIniFixedFilter"
							:rules="bollardRules"
						></pui-select>
					</v-col>
					<v-col cols="6">
						<pui-select
							:id="`bollardend-${modelName}`"
							:attach="`bollardend-${modelName}`"
							:label="$t('stop.bollardend')"
							toplabel
							required
							v-model="model"
							modelName="bollard"
							:itemsToSelect="[{ id: model.bollardendid }]"
							:modelFormMapping="{ id: 'bollardendid' }"
							itemValue="id"
							itemText="bolcode"
							:order="{ orderby: 'asc' }"
							reactive
							:rules="bollardRules"
							:fixedFilter="bollardEndFixedFilter"
						></pui-select>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.stop.cancelStop.title')"
			:modelName="modelName"
			:dialogName="cancelStop"
			:onOk="onOkCancelStop"
			:showDialogProp="showDialogCancelStop"
			:overflow="false"
			:widthDialog="700"
		>
			<template slot="message" slot-scope="data">
				<pui-text-area
					:id="`comments-stop`"
					v-model="data.modalData.comments"
					:label="$t('stop.comments')"
					toplabel
					maxlength="300"
				></pui-text-area>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.stop.undostopauthorization.title')"
			:modelName="modelName"
			:dialogName="undoStopAuthorization"
			:onOk="onOkUndoStopAuthorization"
			:onShow="onShowUndoStopAuthorization"
			:showDialogProp="showDialogUndoStopAuthorization"
		>
			<template slot="message">
				<v-row dense>{{ $t('modal.stop.undostopauthorization.authorizeundo') }} </v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import EventsMixin from '@/mixins/EventsMixin';
import StopFormHeader from './StopFormHeader.vue';

export default {
	name: 'stop-modals',
	mixins: [EventsMixin],
	components: {
		StopFormHeader
	},
	data() {
		return {
			berthType: {},
			alertSubscription: 'alertSubscription',
			showDialogAlertSubscription: true,
			notifications: false,
			modelConfig: null,
			model: {},
			modelTemplate: null,
			checkAll: true,
			events: [],
			validateStopRules: 'validateStopRules',
			showDialogValidateStopRules: true,
			validationDone: false,
			validationResult: '',
			planStop: 'planStop',
			showDialogPlanStop: true,
			stopsToPlan: [],
			authorizeStop: 'authorizeStop',
			showDialogAuthorizeStop: true,
			authorizeStops: 'authorizeStops',
			stopsToAuthorize: [],
			berths: [],
			iniBollards: [],
			endBollards: [],
			pagination: 0,
			bollardsKey: 0,
			denyStops: 'denyStops',
			stopcommentstatus: '',
			showDialogDenyStops: true,
			showDialogInitiateStop: true,
			showDialogUpdateActualStop: true,
			editRequestedBollards: 'editRequestedBollards',
			showDialogEditRequestedBollards: true,
			bollardIniFixedFilter: null,
			bollardEndFixedFilter: null,

			bollardIniActualFixedFilter: null,
			bollardEndActualFixedFilter: null,
			bollardActualKey: 0,
			showDialogFinishStop: true,
			bollardActualFixedFilter: {},
			bollardOrderIni: 0,
			bollardOrderFin: 1,
			cancelStop: 'cancelStop',
			showDialogCancelStop: true,
			undoStopAuthorization: 'undoStopAuthorization',
			showDialogUndoStopAuthorization: true,
			berthsKey: 0,
			updateAuthStop: 'updateAuthStop',
			showDialogUpdateAuthStop: true
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	computed: {
		filterByReal() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'real', op: 'eq', data: true }]
			};
		},
		bollardRules() {
			return [this.bollardOrderIni <= this.bollardOrderFin || this.$t('stop.bollardsException')];
		},
		portIdFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portid', op: 'eq', data: this.model.portid }]
			};
		}
	},
	watch: {
		notifications(newVal) {
			if (!newVal) {
				for (const property in this.model) {
					if (property === 'usr' || property === 'stop' || property === 'visit') {
						continue;
					}
					this.model[property] = false;
				}
			} else {
				if (!this.checkAll) {
					this.checkAll = true;
					return;
				}
				for (const property in this.model) {
					if (property === 'usr' || property === 'stop' || property.includes('send') || property === 'visit') {
						continue;
					}
					this.model[property] = true;
				}
			}
		},
		pagination(newVal) {
			this.getBollards(newVal);
		}
	},
	methods: {
		bollardActualFixedFilterMethod() {
			let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == this.model.berthactual);

			this.bollardIniActualFixedFilter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berthid', op: 'eq', data: this.model.berthactual }]
			};
			this.bollardEndActualFixedFilter = {
				groups: [],
				groupOp: 'and',
				rules: [
					berth && berth.dockid
						? { field: 'dockid', op: 'eq', data: berth.dockid }
						: { field: 'berthid', op: 'eq', data: this.model.berthactual }
				]
			};
		},
		bollardFixedFilter() {
			if (this.model.berthactual) {
				let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == this.model.berthactual);

				this.bollardIniFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'berthid', op: 'eq', data: this.model.berthactual }]
				};
				this.bollardEndFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						berth && berth.dockid
							? { field: 'dockid', op: 'eq', data: berth.dockid }
							: { field: 'berthid', op: 'eq', data: this.model.berthactual }
					]
				};
			} else if (this.model.berthrealid) {
				let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == this.model.berthrealid);
				this.bollardIniFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'berthid', op: 'eq', data: this.model.berthrealid }]
				};
				this.bollardEndFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						berth && berth.dockid
							? { field: 'dockid', op: 'eq', data: berth.dockid }
							: { field: 'berthid', op: 'eq', data: this.model.berthrealid }
					]
				};
			} else {
				let reqLocation = this.model.locationid ? this.model.locationid : this.model.berthsoliid;
				let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == reqLocation);

				this.bollardIniFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'berthid', op: 'eq', data: reqLocation }]
				};
				this.bollardEndFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						berth && berth.dockid ? { field: 'dockid', op: 'eq', data: berth.dockid } : { field: 'berthid', op: 'eq', data: reqLocation }
					]
				};
			}
		},
		changeBollard(bollard, list, position) {
			const selected = list.find((bool) => bool.bolcode == bollard);
			if (position == 'ini') {
				this.bollardOrderIni = selected.orderby;
			} else {
				this.bollardOrderFin = selected.orderby;
			}
		},
		onOkAlertSubscription(modalData) {
			return new Promise(async (resolve) => {
				await this.saveSubscription(modalData);
				resolve(true);
			});
		},

		getPortIdFromVisitId(modalData) {
			const opts = {
				model: 'visit',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: modalData.visitid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0]) {
					modalData.portid = response.data.data[0].portid;
					modalData.portcallnumber = response.data.data[0].portcallnumber;
				}
			});
		},

		onShowDenyStops(modalData) {
			if (modalData.portid == 0) {
				this.getPortIdFromVisitId(modalData);
			}
		},
		onOkDenyStops(modalData) {
			return new Promise(async (resolve) => {
				await this.doDenyStops(modalData);
				resolve(true);
			});
		},
		async doDenyStops(modalData) {
			const data = {
				stopid: modalData.id,
				portid: modalData.portid,
				denyStop: {
					portAuthorityId: modalData.portauthorityid,
					portCallNumber: modalData.portcallnumber,
					stopNumber: modalData.num,
					comments: this.stopcommentstatus
				}
			};
			this.$puiRequests.postRequest(
				'/stop/denyStop',
				data,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogDenyStops = false;
				},
				(error) => {
					this.$puiNotify.error(error.response.data.message);

					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogDenyStops = false;
				}
			);
		},
		onOkInitiateStop(modalData) {
			modalData.startdate = this.model.startdate;
			modalData.berthingtypereal = this.model.berthingtypereal;
			modalData.berthingtype = this.model.berthingtypereal;
			modalData.activitytypereal = this.model.activitytypereal;
			modalData.activitytype = this.model.activitytypereal;
			modalData.berthactual = this.model.berthactual;
			modalData.berthid = this.model.berthactual;
			modalData.bollardinirealid = this.model.bollardinirealid;
			modalData.bollardendrealid = this.model.bollardendrealid;
			modalData.bollardiniid = this.model.bollardinirealid;
			modalData.bollardendid = this.model.bollardendrealid;
			modalData.atavisit = this.model.atavisit;
			modalData.isautoata = false;
			return new Promise(async (resolve) => {
				await this.doInitiateStop(modalData);
				resolve(true);
			});
		},
		onOkUpdateActualStop(modalData) {
			return new Promise(async (resolve) => {
				await this.doUpdateActualStop(modalData);
				resolve(true);
			});
		},
		onOkFinishStop(modalData) {
			modalData.startdate = this.model.ata;
			modalData.enddate = modalData.atd;
			modalData.berthingtypereal = this.model.berthingtypeactual;
			modalData.berthingtype = this.model.berthingtypeactual;
			modalData.activitytypereal = this.model.activitytypeactual;
			modalData.activitytype = this.model.activitytypeactual;
			modalData.berthactual = this.model.berthactual;
			modalData.berthid = this.model.berthactual;
			modalData.bollardinirealid = this.model.bollardinirealid;
			modalData.bollardendrealid = this.model.bollardendrealid;
			modalData.bollardiniid = this.model.bollardiniactual;
			modalData.bollardendid = this.model.bollardendactual;
			modalData.atavisit = this.model.atdvisit;
			modalData.isautoatd = false;
			return new Promise(async (resolve) => {
				await this.doFinishStop(modalData);
				resolve(true);
			});
		},
		onShowAlertSubscription(modalData) {
			this.fillEvents();

			const params = {
				usr: this.$store.getters.getUser,
				stop: modalData.id
			};
			this.$puiRequests.getRequest(
				'/subscriptionstop/get',
				params,
				(response) => {
					this.model = JSON.parse(JSON.stringify(response.data));
					this.modelTemplate = response.data;

					if (this.modelTemplate['usr'] || this.modelTemplate['stop']) {
						this.checkAll = false;
						this.notifications = true;
						for (const property in this.modelTemplate) {
							if (property === 'usr' || property === 'stop') {
								this.modelTemplate[property] = null;
							} else {
								this.modelTemplate[property] = false;
							}
						}
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		fillEvents() {
			const internalEvents = [];

			for (let j = 0, vesselEvent = this.vesselEvent.length; j < vesselEvent; j++) {
				const event = this.vesselEvent[j];
				const eventName = this.allEventsName[event];
				const eventDescription = this.allEventsDescription[event];
				const eventProperty = `stop${event}`;
				const index = internalEvents.length - 1;
				if (event == 'ata' || event == 'atd') {
					internalEvents.splice(index, 0, { name: eventName, description: eventDescription, property: eventProperty });
				}
			}

			this.events = internalEvents;
		},
		async saveSubscription(modalData) {
			if (!this.model.stop && !this.model.usr) {
				if (this.notifications) {
					await this.insertSubscription(modalData);
				}
			} else {
				if (this.notifications) {
					await this.updateSubscription(modalData);
				} else {
					await this.deleteSubscription(modalData);
				}
			}
			this.menu = false;
		},

		async doInitiateStop(modalData) {
			this.$puiRequests.postRequest(
				'/stop/initiateStop',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogInitiateStop = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogInitiateStop = false;
				}
			);
		},
		async doUpdateActualStop(modalData) {
			this.$puiRequests.postRequest(
				'/stop/updateActualStop',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogUpdateActualStop = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogUpdateActualStop = false;
				}
			);
		},
		async doFinishStop(modalData) {
			this.$puiRequests.postRequest(
				'/stop/finishStop',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogFinishStop = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogFinishStop = false;
				}
			);
		},
		insertSubscription(modalData) {
			this.model.usr = this.$store.getters.getUser;
			this.model.stop = modalData.id;
			this.model.visit = modalData.visitid;
			this.$puiRequests.postRequest(
				'/subscriptionstop/insert',
				this.model,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogAlertSubscription = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogAlertSubscription = false;
				}
			);
		},
		updateSubscription(modalData) {
			this.$puiRequests.putRequest(
				'/subscriptionstop/update',
				this.model,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogAlertSubscription = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogAlertSubscription = false;
				}
			);
		},
		deleteSubscription(modalData) {
			const params = {
				usr: this.$store.getters.getUser,
				stop: modalData.id
			};
			this.$puiRequests.deleteRequest(
				'/subscriptionstop/delete',
				null,
				() => {
					this.model = JSON.parse(JSON.stringify(this.modelTemplate));
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiNotify.success(this.$t('pui9.delete.success'));

					this.showDialogAlertSubscription = false;
				},

				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogAlertSubscription = false;
				},
				params
			);
		},
		//onShowValidateStopRules(modalData) {
		//	const params = {
		//		stopid: modalData.id
		//	};
		//	this.$puiRequests.getRequest(
		//		'/stop/validateStopRules',
		//		params,
		//		(response) => {
		//			this.validationResult = response.data;
		//			this.validationDone = true;
		//		},
		//		(error) => {
		//			this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
		//		}
		//	);
		//},
		onShowPlanStop(modalData) {
			this.stopsToPlan = [];
			this.berthType.berthingtypecode = null;
			this.berthType.berthingtypename = null;

			this.onShowPlanStops(modalData);
		},
		onShowPlanStops(modalData) {
			this.stopsToPlan = [];
			this.pagination = 0;

			modalData.rows.forEach((element) => {
				element.berthplan = element.locationberthcode;
				element.bollardiniplan = element.bolcodeini ? element.bolcodeini : element.bollardCodeIniReq;
				element.bollardendplan = element.bolcodeend ? element.bolcodeend : element.bollardCodeEndReq;
				element.berthingtypeplan = element.berthingtypereq ? element.berthingtypereq : element.berthingtypesoli;
				element.etaplan = element.eta ? element.eta : element.startdatesoli;
				element.etdplan = element.etd ? element.etd : element.enddatesoli;
				this.stopsToPlan.push(element);
				this.getBollards(0);
				this.berthType.berthingtypecode = element.berthingtype;
				this.berthType.berthingtypename = element.berthingtypename;
			});
			this.berthsKey++;
		},

		onOkPlanStops() {
			let url = '/stop/planStops';
			let toPlan = [];
			this.stopsToPlan.forEach((stop) => {
				toPlan.push({
					portAuthorityId: stop.portauthorityid,
					portCode: stop.portcode,
					portCallNumber: stop.portcallnumber,
					stopNumber: stop.num,
					berthCode: stop.berthplan,
					bollardIni: stop.bollardiniplan,
					bollardEnd: stop.bollardendplan,
					etaPlan: stop.etaplan,
					etdPlan: stop.etdplan,
					berthingType: this.berthType.berthingtypecode
				});
			});
			return new Promise(async (resolve) => {
				this.$puiRequests.postRequest(
					url,
					toPlan,
					(response) => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
						this.showDialogPlanStop = false;
						this.$puiEvents.$emit('berthplanner-toolbar_plan');
						resolve(true);
					},
					(error) => {
						resolve(false);
						this.$puiNotify.error(error.response.data.message);
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
						this.showDialogPlanStop = false;
					}
				);
			});
		},
		onShowAuthorizeStop(modalData) {
			this.stopsToAuthorize = [];
			this.berthType.berthingtypecode = null;
			this.berthType.berthingtypename = null;

			this.onShowAuthorizeStops(modalData);
		},
		onShowAuthorizeStops(modalData) {
			this.stopsToAuthorize = [];
			this.pagination = 0;

			modalData.rows.forEach((element) => {
				element.berthauth = element.locationberthcode;
				element.bollardiniauth = element.bolcodeini ? element.bolcodeini : element.bollardCodeIniReq;
				element.bollardendauth = element.bolcodeend ? element.bolcodeend : element.bollardCodeEndReq;
				element.berthingtypeauth = element.berthingtypereq ? element.berthingtypereq : element.berthingtypesoli;
				element.etaauth = element.eta ? element.eta : element.startdatesoli;
				element.etdauth = element.etd ? element.etd : element.enddatesoli;
				this.stopsToAuthorize.push(element);
				this.getBollards(0);
				this.berthType.berthingtypecode = element.berthingtype;
				this.berthType.berthingtypename = element.berthingtypename;
			});
			this.berthsKey++;
			this.updateEntryDate();
		},
		onOkAuthorizeStop() {
			let url = '/pms/authorizeStops';
			let toAuth = [];
			this.stopsToAuthorize.forEach((stop) => {
				toAuth.push({
					portAuthorityId: stop.portauthorityid,
					portCode: stop.portcode,
					portCallNumber: stop.portcallnumber,
					stopNumber: stop.num,
					berthCode: stop.berthauth,
					bollardIni: stop.bollardiniauth,
					bollardEnd: stop.bollardendauth,
					etaAuth: stop.etaauth,
					etdAuth: stop.etdauth,
					berthingType: this.berthType.berthingtypecode,
					comments: stop.comments,
					pbaAuthorized: stop.pbaauthorized
				});
			});
			return new Promise(async (resolve) => {
				this.$puiRequests.postRequest(
					url,
					toAuth,
					(response) => {
						// Clean arrays
						this.$store.commit('setStopsFeaturesInstantAU', []);
						this.$store.commit('setStopsFeaturesInstantModifiedAU', []);
						this.$store.commit('setStopsFeaturesInstantWithModifiedAU', []);
						this.$store.commit('setStopsFeaturesInstantRemovedAU', []);
						// Refrescar mapa
						this.$puiEvents.$emit('map:updated');
						//Cerrar modal
						this.$puiNotify.success(this.$t('shipsituation.resquesteds.authorization.successSingle'));
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
						this.showDialogAuthorizeStop = false;
						resolve(true);
					},
					(error) => {
						this.$puiNotify.error(error.response.data.message);
						resolve(false);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
					}
				);
			});
		},
		onShowInitiateStop(modalData) {
			const params = {
				pk: {
					id: modalData.id
				},
				state: 'AU'
			};
			this.$puiRequests.getRequest(
				'/stop/getDataAtState',
				params,
				(response) => {
					this.model = {
						...response.data,
						...modalData,
						startdate: response.data.startdate,
						enddate: response.data.enddate,
						berthactual: response.data.berthid,
						bollardinirealid: response.data.bollardiniid,
						bollardendrealid: response.data.bollardendid,
						berthingtypereal: response.data.berthingtype,
						activitytypereal: response.data.activitytype,
						portid: modalData.portid
					};
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
			this.$puiRequests.getRequest(
				'/movementsequencestep/getVisitAta',
				{ stopId: modalData.id },
				(response) => {
					this.model.atavisit = response.data;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		onShowUpdateActualStop(modalData) {
			const params = {
				pk: {
					id: modalData.id
				},
				state: modalData.statusid
			};
			this.$puiRequests.getRequest(
				'/stop/getDataAtState',
				params,
				(response) => {
					this.model = {
						...response.data,
						...modalData,
						startdate: response.data.startdate,
						enddate: response.data.enddate,
						berthrealid: response.data.berthid,
						bollardiniid: response.data.bollardiniid,
						bollardendid: response.data.bollardendid,
						berthingtypereal: response.data.berthingtype,
						activitytypereal: response.data.activitytype,
						portid: modalData.portid

					};
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		onShowFinishStop(modalData) {
			const params = {
				pk: {
					id: modalData.id
				},
				state: 'IN'
			};
			this.$puiRequests.getRequest(
				'/stop/getDataAtState',
				params,
				(response) => {
					this.model = {
						...response.data,
						...modalData,
						ata: response.data.startdate,
						atd: response.data.enddate,
						berthactual: response.data.berthid,
						bollardiniactual: response.data.bollardiniid,
						bollardendactual: response.data.bollardendid,
						berthingtypeactual: response.data.berthingtype,
						activitytypeactual: response.data.activitytype,
						portid: modalData.portid
					};
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
			this.$puiRequests.getRequest(
				'/movementsequencestep/getVisitAtd',
				{ stopId: modalData.id },
				(response) => {
					this.model.atdvisit = response.data;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		getBollards(page) {
			let stop = this.stopsToAuthorize.length > 0 ? this.stopsToAuthorize[page] : this.stopsToPlan[page];

			let berth = this.$store.getters.getRawPortBerths.find((berth) =>
				stop.berthauth ? berth.berthcode == stop.berthauth : berth.berthcode == stop.berthplan
			);
			let usedBerth = stop.berthauth ? stop.berthauth : stop.berthplan;
			const optsIniBo = {
				model: 'bollard',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'berthcode', op: 'eq', data: usedBerth },
						berth.dockid ? { field: 'dockid', op: 'eq', data: berth.dockid } : null
					]
				},
				order: [
					{
						column: 'orderby',
						direction: 'asc'
					}
				],
				rows: null
			};
			this.$puiRequests.postRequest('/puisearch', optsIniBo, (response) => {
				if (response.data.data) {
					this.iniBollards = response.data.data;
				}
			});

			const optsEndBo = {
				model: 'bollard',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [berth.dockid ? { field: 'dockid', op: 'eq', data: berth.dockid } : { field: 'berthcode', op: 'eq', data: usedBerth }]
				},
				order: [
					{
						column: 'orderby',
						direction: 'asc'
					}
				],
				rows: null
			};
			this.$puiRequests.postRequest('/puisearch', optsEndBo, (response) => {
				if (response.data.data) {
					this.endBollards = response.data.data;
				}
			});
		},
		next() {
			if (this.stopsToAuthorize.length - 1 > this.pagination) {
				this.pagination++;
			}
		},
		previous() {
			if (this.pagination > 0) {
				this.pagination--;
			}
		},
		onShowEditRequestedBollards(modalData) {
			this.model = modalData;
		},
		onOkEditRequestedBollards() {
			const infoToEdit = {
				id: this.model.id,
				bollardini: this.model.bollardiniid,
				bollardend: this.model.bollardendid
			};
			return new Promise(async (resolve) => {
				await this.updateStopBollardsRequ(infoToEdit);
				resolve(true);
			});
		},
		updateStopBollardsRequ(infoToEdit) {
			if (infoToEdit) {
				const url = '/stop/updateStopBollardsRequ';
				const params = { pk: { id: infoToEdit.id }, bollardini: infoToEdit.bollardini, bollardend: infoToEdit.bollardend };
				this.doPost(url, params);
			}
		},
		doPost(url, params) {
			this.$puiRequests.postRequest(
				url,
				params,
				(response) => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
					this.showDialogEditRequestedBollards = false;
				},
				(error) => {
					this.$puiNotify.error(this.$t('pui9.save.error'));
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
					this.showDialogEditRequestedBollards = false;
				},
				params,
				params
			);
		},
		onOkCancelStop(modalData) {
			modalData.startdate = modalData.eta;
			modalData.enddate = modalData.etd;
			modalData.berthid = modalData.locationid;

			return new Promise(async (resolve) => {
				await this.$puiRequests.postRequest(
					'/pms/cancelStop',
					modalData,
					() => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showDialogCancelStop = false;
						resolve(true);
					},
					(error) => {
						this.$puiNotify.error(error.response.data.message);

						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showDialogCancelStop = false;
						resolve(false);
					}
				);
			});
		},
		onShowUndoStopAuthorization(modalData) {
			this.model = modalData;
		},
		onOkUndoStopAuthorization(modalData) {
			return new Promise(async (resolve) => {
				modalData.portcode = modalData.portcode ? modalData.portcode : this.model.portcode;
				modalData.portcallnumber = modalData.portcallnumber ? modalData.portcallnumber : this.model.portcallnumber;

				const url = this.$store.getters.getModelByName(this.modelName).url.undoStopAuthorization;

				this.$puiRequests.postRequest(
					url,
					modalData,
					() => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showDialogUndoStopAuthorization = false;
						resolve(true);
					},
					(error) => {
						this.$puiNotify.error(error.response.data.message);
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showDialogUndoStopAuthorization = false;
						resolve(false);
					}
				);
			});
		},
		updateEntryDate() {
			let url = '/locationsequenceheader/calculatePBAauth';
			let stop = {
				portAuthorityId: this.stopsToAuthorize[0].portauthorityid,
				portCode: this.stopsToAuthorize[0].portcode,
				portCallNumber: this.stopsToAuthorize[0].portcallnumber,
				stopNumber: this.stopsToAuthorize[0].num,
				berthCode: this.stopsToAuthorize[0].berthauth,
				bollardIni: this.stopsToAuthorize[0].bollardiniauth,
				bollardEnd: this.stopsToAuthorize[0].bollardendauth,
				etaAuth: this.stopsToAuthorize[0].etaauth,
				etdAuth: this.stopsToAuthorize[0].etdauth,
				berthingType: this.berthType.berthingtypecode,
				comments: this.stopsToAuthorize[0].comments,
				pbaAuthorized: this.stopsToAuthorize[0].pbaauthorized
			};

			this.$puiRequests.postRequest(
				url,
				stop,
				(response) => {
					this.stopsToAuthorize[0].pbaauthorized = response.data;
					console.log(this.stopsToAuthorize);
					this.keyPbaAuth++;
				},
				(error) => {
					this.$puiNotify.error(error.response.data.message);
				},
				null
			);
		},
		bollardIniAuthFixedFilter(modalData) {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berthid', op: 'eq', data: modalData.locationid }]
			};
		},
		bollardEndAuthFixedFilter(modalData) {
			let berth = this.$store.getters.getRawPortBerths.find((berth) => berth.id == modalData.locationid);

			return {
				groups: [],
				groupOp: 'and',
				rules: [
					berth && berth.dockid
						? { field: 'dockid', op: 'eq', data: berth.dockid }
						: { field: 'berthid', op: 'eq', data: modalData.locationid }
				]
			};
		},
		onShowUpdateAuthStop(modalData) {
			modalData.locationid ??= modalData.berthauthid;
			modalData.eta ??= modalData.startdateauth;
			modalData.etd ??= modalData.enddateauth;
			modalData.bollardiniid ??= modalData.bollardiniauthid;
			modalData.bollardendid ??= modalData.bollardendauthid;
			modalData.berthingtype ??= modalData.berthingtypeauth;
			modalData.pbaauthorized ??= modalData.pbaauthorized;
			modalData.comments ??= modalData.comments;
		},
		onOkUpdateAuthStop(modalData) {
			return new Promise(async (resolve) => {
				let url = '/stop/updateAuthStop';
				let stop = {
					berthid: modalData.locationid,
					bollardiniid: modalData.bollardiniid,
					bollardendid: modalData.bollardendid,
					berthingtype: modalData.berthingtype,
					startdate: modalData.eta,
					pbaauthorized: modalData.pbaauthorized,
					enddate: modalData.etd,
					comments: modalData.comments
				};

				let pk = {
					id: modalData.id
				};

				this.$puiRequests.patchRequest(
					url,
					stop,
					(response) => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
						this.showDialogAuthorizeStop = false;
						resolve(true);
					},
					(error) => {
						this.$puiNotify.error(error.response.data.message);
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showDialogAuthorizeStop = false;
						resolve(false);
					},
					pk
				);
			});
		}
	},
	mounted() {
		this.$puiEvents.$on(`onPuiSelect_selectedItems-bollardini-${this.modelName}`, (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel) {
				this.bollardOrderIni = lupaModel.orderby;
			} else {
				this.bollardOrderIni = 0;
			}
		});
		this.$puiEvents.$on(`onPuiSelect_selectedItems-bollardend-${this.modelName}`, (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel) {
				this.bollardOrderFin = lupaModel.orderby;
			} else {
				this.bollardOrderFin = 1;
			}
		});
	},
	destroyed() {
		this.$puiEvents.$off(`onPuiSelect_selectedItems-bollardini-${this.modelName}`);
		this.$puiEvents.$off(`onPuiSelect_selectedItems-bollardend-${this.modelName}`);
	}
};
</script>
<style lang="postcss">
.customHeader {
	background-color: #f5f7f7;
}
</style>
